import type { GetServerSideProps } from 'next';

import { FALLBACK_LNG } from '@app/_internal/i18n/settings';
import { auth } from '@app/_internal/utils/auth';

import { LoginPage } from '@/feature-auth';
import { withAuthLayout } from '@/layouts/AuthSharedLayout';
import { AppRoutes } from '@/routes';

function Page() {
  return <LoginPage />;
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { params } = context;
  const lang = params?.lang || FALLBACK_LNG;

  let callbackUrl = `/${lang}${AppRoutes.INDEX}`;

  if (Array.isArray(params?.callbackUrl) && params?.callbackUrl.length) {
    callbackUrl = params.callbackUrl[params.callbackUrl.length - 1]; // get the last item
  } else if (typeof params?.callbackUrl === 'string') {
    callbackUrl = params.callbackUrl;
  }

  const session = await auth(context.req, context.res);
  if (session && session.error == null) {
    return {
      redirect: {
        destination: callbackUrl,
        permanent: false,
      },
    };
  }

  return {
    props: {},
  };
};

export default withAuthLayout(Page);
